import React, { useState, createRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default function() {
    const recaptchaRef = createRef();

    const [capVal, setCapVal] = useState(null);

    return (
        <div className='bg-white flex-col justify-start items-center inline-flex mb-12 sm:mb-24 w-full'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/contact/header.jpeg'}
                    alt={'Kind in permacultuur-leertuin aan het werk'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='max-w-screen-xl m-auto mt-20 px-5 sm:px-12'>
                <div className='gap-5 lg:inline-flex mb-12 sm:mb-24 fade-in'>
                    <div
                        className='grow shrink basis-0 p-5 sm:p-12 bg-white rounded-lg full-shadow flex-col justify-start items-start gap-7 inline-flex'>
                        <div>
                            <h3
                                className='text-dark mb-2.5'>Contact
                            </h3>
                            <p
                                className='text-dark'>Wilt
                                u graag iets over een project of een van de tuinen zeggen of weten, heeft u
                                aanvullingen, ideeën of opbouwende kritiek voor de website of wilt u op uw school
                                een leertuin realiseren, neem dan contact op met het permacultuur-leertuinen team
                                via e-mail, telefoon of onderstaand contactformulier.
                            </p>
                        </div>
                        <form action='contact.php' method='POST' className={'w-full'}>
                            <div className='self-stretch flex-col justify-start items-start gap-2.5 flex'>
                                <input
                                    className='px-2.5 py-3 outline-none rounded-lg border border-stone-300 focus:border-stone-400 w-full text-black text-sm font-normal leading-loose'
                                    placeholder={'Naam *'}
                                    name={'name'}
                                    required />
                                <input
                                    className='px-2.5 py-3 outline-none rounded-lg border border-stone-300 focus:border-stone-400 w-full text-black text-sm font-normal leading-loose'
                                    placeholder={'Email *'}
                                    name={'email'}
                                    type={'email'}
                                    required />
                                <input
                                    className='px-2.5 py-3 outline-none rounded-lg border border-stone-300 focus:border-stone-400 w-full text-black text-sm font-normal leading-loose'
                                    placeholder={'Onderwerp *'}
                                    name={'subject'}
                                    required />
                                <textarea
                                    className='px-2.5 py-1 outline-none rounded-lg border border-stone-300 focus:border-stone-400 w-full text-black text-sm resize-none font-normal leading-loose'
                                    placeholder={'Bericht *'}
                                    name={'message'}
                                    rows={3}
                                    required />
                            </div>
                            <div className='mt-7 flex items-center justify-between gap-2.5 flex-wrap'>
                                <div className='flex-1'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Lfe3VgpAAAAAEOybcO8Brtjtmcx0KCOr0dfJKzt"
                                        onChange={(val) => setCapVal(val)}
                                    />
                                </div>
                                <div className='w-full sm:w-auto flex justify-end'>
                                    <button
                                        type={'submit'}
                                        disabled={!capVal}
                                        className='px-5 py-2 hover:enabled:bg-green border-green border duration-300 rounded-full text-green hover:enabled:text-light disabled:cursor-not-allowed text-base sm:text-lg font-semibold leading-loose'>
                                        Versturen
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:flex-col items-center gap-7 lg:inline-flex lg:w-auto mt-6 lg:mt-0'>
                        <div
                            className='p-5 bg-white rounded-lg full-shadow h-full w-full'>
                            <h2 className='text-dark mb-2.5'>Gegevens</h2>
                            <p className='text-dark font-semibold'>Ishwa</p>
                            <p className='text-dark'>du Fijan, De Steeg<br/>06 - 12 12 20 22<br/></p>
                            <a className='text-dark underline' href={'mailto:idufijan@hotmal.com'}>idufijan@hotmal.com</a>
                        </div>
                        <img className='grow rounded-lg lg:w-[278px] lg:h-[440px] object-cover' alt={'Ingeborg du Fijan, oprichter permacultuur-leertuinen'}
                             src='/images/contact/image1.jpeg'/>
                    </div>
                </div>
                <div className='text-center fade-in'>
                    <h2 className='text-dark'>Onze
                        Sponsoren
                    </h2>
                    <div className='self-stretch justify-center items-center gap-7 inline-flex flex-wrap'>
                        <a href={'https://www.utrecht.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-36 h-20' alt={'Gemeente Utrecht logo'} src='/images/contact/sponsor1.jpeg'/></a>
                        <a href={'https://www.nmu.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-36 h-20' alt={'Natuur en Milieufederatie Utrecht logo'} src='/images/contact/sponsor2.jpeg'/></a>
                        <a href={'https://www.de.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-28 h-28' alt={'Douwe Egberts logo'} src='/images/contact/sponsor3.jpeg'/></a>
                        <a href={'https://www.praxis.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-36 h-9' alt={'Praxis logo'} src='/images/contact/sponsor4.jpeg'/></a>
                        <a href={'https://natuurcentrumarnhem.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-32 h-20' alt={'Natuurcentrum Arnhem logo'} src='/images/contact/sponsor5.jpeg'/></a>
                        <a href={'https://www.nldoet.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-24 h-24' alt={'NLdoet logo'} src='/images/contact/sponsor6.jpeg'/></a>
                        <a href={'https://www.arnhem.nl/'} rel={'noreferrer'} target={'_blank'}><img className='w-44 h-44' alt={'Gemeente Arnhem logo'} src='/images/contact/sponsor7.jpeg'/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}
